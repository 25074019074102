.app_navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    padding: 1rem 2rem;
}
.app_navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.app_navbar-logo img{
    width: 150px;

}
.app_navbar-links{
    flex: 1;
    display: flex;
    color: var(--color-white);
    justify-content: center;
    align-items: center;
    list-style: none;
}
.app_navbar-links li{
    margin: 0 1rem;
    cursor: pointer;
}
.app_navbar-links li:hover{
    color: var(--color-grey);
}
.app_navbar-login{
    display: flex;
    justify-content: flex-end;
    color: var(--color-white);
    align-items: center;
}
.app_navbar-login a{
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;
}
.app_navbar-login a:hover{
    border-bottom: 1px solid var(--color-golden);
}
.app_navbar-login div{
    width: 1px;
    height: 30px;
    background-color: var(--color-grey);
}
.app_navbar-smallscreen{
  display: none;  
}
.app_navbar-smallscreen_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}
.app_navbar-smallscreen_overlay  .overlay_close{
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;
    position: absolute;
    top:20px;
    right: 20px;
}
.app_navbar-smallscreen-links{
    list-style: none;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 12rem;
}
.app_navbar-smallscreen-links li{
  margin: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-golden);
  cursor: pointer;
  align-items: center;
  font-family: var(--font-base);
}
.app_navbar-smallscreen-links li:hover{
    color:var(--color-white);
}
@media screen and (max-width:2000px) {
.app_navbar-logo img{
    width: 210px;
}
}
@media screen and (max-width:1150px) {
    .app_navbar-links{
        display: none;
    }
    .app_navbar-smallscreen{
        display: flex;
    }

}
@media screen and (max-width:650px) {
    .app_navbar{
        padding: 1rem;
    }
    .app_navbar-login{
        display: none;
    }
    .app_navbar-logo img{
        width: 110px;
    }
}